import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from '../../../../../redux/store';
import SinglePopup from './SinglePopup';
import useRefCustom from '../../../../../hooks/useRefCustom';
import useUserPreference from '../../../../../hooks/useUserPreference';
import { removePopup, addPopup } from '../../../../../redux/slices/notification';
import useWebSocket from '../../../../../hooks/useWS';

const NOTY_WHITELABEL_POPUP = 'noty_whitelabel_popup';

function AutoDetectPopups() {
  const { popups } = useSelector((state) => state.noti);

  const { wsClient } = useWebSocket();

  const { shouldShowMessage, shouldShowMessageToday } = useUserPreference();

  const [popup, setPopup] = useState(null);

  useEffect(() => {
    if (wsClient) {
      const handleMessage = (data) => {
        if (data?.type === NOTY_WHITELABEL_POPUP) {
          addPopup(data);
        }
      };

      wsClient?.on('message', handleMessage);

      return () => {
        wsClient?.off('message', handleMessage);
      };
    }
  }, [wsClient]);

  useEffect(() => {
    // Format data
    const temp = popups
      ?.map((item) => {
        const isPersistent = item?.data?.is_persistent;
        const content = item?.data?.content;

        if (isPersistent && !shouldShowMessageToday(item?.id)) {
          return null;
        }
        if (!isPersistent && !shouldShowMessage(item?.id)) {
          return null;
        }

        return {
          id: item?.id,
          isPersistent,
          content,
        };
      })
      ?.filter((item) => item);

    if (temp?.length !== 0) {
      setPopup(temp[0]);
    } else {
      setPopup(null);
    }
  }, [popups]);

  const handleNextPopup = (popupId) => {
    // Logic auto pop popup item
    removePopup(popupId);
  };

  return <>{popup && <SinglePopup data={popup} next={handleNextPopup} />}</>;
}

AutoDetectPopups.propTypes = {};

export default AutoDetectPopups;
