export default function useUserPreference() {
  // To set the preference
  const setPreference = (key) => {
    localStorage.setItem(key, 'false'); // Store a key-value pair indicating the message should not be shown
  };

  // To check the preference
  const shouldShowMessage = (key) => {
    return localStorage.getItem(key) !== 'false';
  };

  // To set the preference for today
  const setPreferenceToday = (key) => {
    const today = new Date().toISOString().split('T')[0]; // Get today's date in YYYY-MM-DD format
    localStorage.setItem(key, today);
  };

  // To check the preference
  const shouldShowMessageToday = (key) => {
    const today = new Date().toISOString().split('T')[0];
    const storedDate = localStorage.getItem(key);
    return storedDate !== today; // Show message if the stored date is not today
  };
  return {
    setPreference,
    setPreferenceToday,
    shouldShowMessage,
    shouldShowMessageToday,
  };
}
