import PropTypes from 'prop-types';
import { createContext, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import useBoolean from '../../../../../hooks/useBoolean';
import useFBContext from '../../../../../hooks/useFBContext';
import useToolContext from '../../../../../hooks/useToolContext';
import useToolFunction from '../../../../../hooks/useToolFunction';
import useRefCustom from '../../../../../hooks/useRefCustom';
import { delayFunc, randomInRange } from '../../../../../utils/others';
import { EmitterService } from '../../../../../utils/event';

const initialState = {
  comments: [],
  isRunning: false,
  friends: [],
  emitterManageUI: { on: () => {}, off: () => {}, send: () => {} },
  onCancel: () => {},
  onStartInteract: () => {},
  getFilterData: () => {},
  updateFilterSetting: () => {},
  onGetReactions: () => {},
  getActionsSetting: () => {},
  updateActionsSetting: () => {},
  onAddListFriends: () => {},
};

const InteractiveContext = createContext(initialState);

const ACTIONS_NEED_POST = ['comment', 'like'];

const ACTIONS_RESULT = ['comments', 'likes', 'inbox', 'poke', 'remove_friend'];

const DELAY_GET_DATA = 5 * 1000;

const POSTS_OF_PAGE = 50;

export const DISABLE_FILTER = 'disable_filter';

export const DISABLE_ACTIONS = 'disable_actions';

export const SORT = 'sort-reactions';

// ---------------------- PROPS VALIDATE ---------------------
InteractiveProvider.propTypes = {
  children: PropTypes.any,
};
// -----------------------------------------------------------

function InteractiveProvider({ children }) {
  const [emitterManageUI, setEmitterManageUI] = useState(new EmitterService());

  const [postQueues, setPostQueues, postQueuesRef] = useRefCustom([]);

  const [, setFilterData, filterDataRef] = useRefCustom({});

  const [, setActionsSetting, actionsSettingRef] = useRefCustom({});

  const [, setPosts, postsRef] = useRefCustom([]);

  const isRunning = useBoolean();

  const { FBUser } = useFBContext();

  const [, setFBUserRef, FBUserRef] = useRefCustom('');

  const [friends, setFriends, friendsRef] = useRefCustom([]);

  const [, setSetting, settingRef] = useRefCustom({});

  const [, setTimelineCursors, timelineCursorsRef] = useRefCustom({});

  const taskRef = useRef();

  const { checkCommonData, updateTaskRunningStatus } = useToolContext();

  const {
    emitter,
    getFriends,
    interactFriend,
    getTimelinePostsOfUser,
    onLoadAccessToken,
    getPostsInProfile,
    getReactionsInPost,
    getMessengerInfo,
    getCommentInfoInPost,
    getFollowers,
    getReactionsFromFeedback,
    getTimelinePostsOfPage,
    addListFriends,
    stopQueue,
    restartSignal,
  } = useToolFunction();

  useEffect(() => {
    if (emitter) {
      const handleMessage = () => {
        isRunning.onFalse();
      };

      emitter?.on('stop', handleMessage);
      emitter?.on('checkpoint', handleMessage);

      return () => {
        emitter?.off('stop', handleMessage);
        emitter?.off('checkpoint', handleMessage);
      };
    }
  }, [emitter]);

  const onLoadFriends = async () => {
    const messengerInfo = await getMessengerInfo();
    const reformatData = messengerInfo
      ?.filter((item) => item?.thread_type === 'ONE_TO_ONE')
      ?.reduce((res, item) => {
        const userId = item?.thread_key?.other_user_id;
        if (userId) {
          res[userId] = item?.messages_count || 0;
        }
        return res;
      }, {});
    const isPage = FBUserRef?.current?.isPageProfile || false;
    let data = isPage ? await getFollowers() : await getFriends();
    data = data?.map((item) => ({ ...item, messagesCount: reformatData[item?.uid] }));
    setFriends(data || []);
  };

  useEffect(() => {
    if (FBUser && friendsRef?.current?.length === 0) {
      onLoadFriends();
    }
  }, [FBUser]);

  useEffect(() => {
    setFBUserRef(FBUser);
  }, [FBUser]);

  const getExcludesData = useCallback(() => {
    return postQueuesRef?.current?.reduce(
      (res, item) => {
        const actorId = item?.friendId;
        ['inbox'].forEach((key) => {
          if (item[key] && res[key]?.indexOf(actorId) === -1) {
            res[key].push(actorId);
          }
        });

        return res;
      },
      {
        inbox: [],
      }
    );
  }, [postQueues]);

  // ==================================================================

  const getUserInformation = (id) => {
    return (friendsRef?.current || [])?.find((item) => item?.uid === id);
  };

  const getPostsFromUser = async (friendId, amount = 3) => {
    try {
      const currentData = timelineCursorsRef?.current || {};
      // console.log('getPostsFromUser', friendId, amount);

      const { cursor } = currentData[friendId] || {};
      const data = await getTimelinePostsOfUser(friendId, amount, cursor);
      // console.log('getPostsFromUser',data);
      if (data) {
        const { posts, nextCursor } = data;
        currentData[friendId] = { cursor: nextCursor };
        setTimelineCursors({ ...currentData });
        return posts;
      }
      return [];
    } catch (error) {
      console.log(error);
      return [];
    }
  };

  const randomFriendInteract = () => {
    try {
      const friendIds = Object.keys(timelineCursorsRef?.current);

      if (friendIds?.length === 0) {
        return null;
      }

      const currentQueue = postQueuesRef?.current || [];
      const excludeFriends = currentQueue
        ?.filter((item) => ACTIONS_RESULT?.some((key) => item[key]))
        ?.map((item) => item?.friendId);

      // We recommend friends who haven't do any actions yet
      const highPriorityFriends = friendIds?.filter(
        (item) => !timelineCursorsRef?.current[item] && excludeFriends?.indexOf(item) === -1
      );

      if (highPriorityFriends?.length !== 0) {
        const index = randomInRange({ from: 0, to: highPriorityFriends?.length - 1 });
        return highPriorityFriends[index];
      }

      const index = randomInRange({ from: 0, to: friendIds?.length - 1 });
      return friendIds[index];
    } catch (error) {
      console.log(error);
      return null;
    }
  };

  const interactCallback = (data) => {
    console.log('interactCallback', data);
    const { friendId, result } = data || {};

    const newArray = [...postQueuesRef?.current];
    const index = newArray.findIndex((item) => item?.friendId === friendId);
    if (index !== -1) {
      if (result?.comment) {
        const currentComments = newArray[index]?.comments || [];
        currentComments.push(result?.comment);
        newArray[index] = { ...newArray[index], comments: [...currentComments] };
      } else if (result?.like) {
        const currentLikes = newArray[index]?.likes || [];
        currentLikes.push(result?.like);
        newArray[index] = { ...newArray[index], likes: [...currentLikes] };
      } else {
        newArray[index] = { ...newArray[index], ...(result || {}) };
      }
    }
    setPostQueues([...newArray]);
  };

  const loopTask = async (contents, friendId) => {
    /**
     * ['inbox', 'comment', 'like', 'poke', 'add_friend', 'remove_friend']
     */
    const { delay, actions, postAmount } = settingRef?.current || {};
    // const { from, to } = delay || { from: 60, to: 120 };
    // const CYCLE_DELAY = randomInRange({ from, to }) * 1000;
    let posts = [];
    // const friendId = randomFriendInteract();
    console.log('Selected friend id:', friendId);
    const needPost = actions?.some((item) => ACTIONS_NEED_POST.indexOf(item) !== -1);
    // Some actions need post to interact. Eg: comment, like
    const haveLikeAction = actions.indexOf('like') !== -1;
    if (needPost) {
      let finalPostAmount = 1;
      if (haveLikeAction) {
        finalPostAmount = postAmount || 1;
      }
      posts = await getPostsFromUser(friendId, finalPostAmount);
    }

    const isPageProfile = FBUserRef?.current?.isPageProfile || false;
    const friendInformation = getUserInformation(friendId);
    const isTextedWithPageBefore = friendInformation?.messagesCount && friendInformation?.messagesCount > 0;

    const data = {
      posts,
      delay,
      actions,
      friendId,
      contents,
      excludesData: getExcludesData(),
      isPageProfile,
      isTextedWithPageBefore,
    };

    return await interactFriend(data, interactCallback);
    // const { isOperationCanceled } = await interactFriend(data, interactCallback);
    // if (isOperationCanceled) {
    //   console.log('Break cycle!! STOPPED.');
    //   onReset();
    //   return;
    // }
    // console.log('Run next cycle!');
    // if (taskRef?.current) {
    //   clearTimeout(taskRef?.current);
    // }

    // taskRef.current = setTimeout(() => {
    //   loopTask(contents);
    // }, CYCLE_DELAY);
  };

  const onReset = () => {
    setPosts([]);
    isRunning.onFalse();
    if (taskRef?.current) {
      clearTimeout(taskRef?.current);
    }
  };

  const onStartInteract = async (data) => {
    if (!checkCommonData()) {
      return;
    }

    isRunning.onTrue();
    const { friends, contents, setting: settingFromUser } = data;
    setPostQueues(friends?.map((uid) => ({ friendId: uid })));
    setSetting(settingFromUser);
    setTimelineCursors(friends?.reduce((res, uid) => ({ ...res, [uid]: null }), {}));

    try {
      const { from, to } = settingFromUser?.delay || { from: 60, to: 120 };
      const CYCLE_DELAY = randomInRange({ from, to }) * 1000;
      for (const [taskIndex, friend] of friends.entries()) {
        const { isOperationCanceled } = await loopTask(contents, friend);
        if (isOperationCanceled) {
          console.log('Break cycle!! STOPPED.');
          onReset();
          return;
        }
        const isFinalTask = friends?.length - 1 === taskIndex;
        if (!isFinalTask) {
          await delayFunc(CYCLE_DELAY);
        }
      }
      onReset();
    } catch (error) {
      console.log(error);
    }
  };

  // ====================== GET REACTIONS, COMMENTS, MESSAGES ======================

  const resetStats = () => {
    const currentFriends = friendsRef?.current || [];
    const newValue = currentFriends?.map((item) => ({ ...item, reaction: 0, comment: 0 }));
    setFriends([...newValue]);
  };

  const updateStatForFriend = (name, uid) => {
    const currentFriends = friendsRef?.current || [];
    const index = currentFriends?.findIndex((item) => item?.uid === uid);
    if (index !== -1) {
      const currentValue = currentFriends[index][name] || 0;
      currentFriends[index][name] = currentValue + 1;
      setFriends([...currentFriends]);
    }
  };

  const callbackReaction = (data) => {
    /**
     * Example item in data:
     * 61554669931019 (This is uid of facebook user)
     */
    console.log('callbackReaction', data);
    if (data && Array.isArray(data)) {
      data?.forEach((friendId) => {
        updateStatForFriend('reaction', friendId);
      });
    }
  };

  const callbackComment = (data) => {
    /**
     * Example item in data:
     * {
        "feedbackId": "Y29tbWVudDoxMDU4NjM0NTk1MjAxMDgwXzE0NjQ1NTkwODQyNDc2NjU=",
        "type": "User",
        "id": "61554669931019",
        "name": "Huy Nguyen",
        "picture": "https://scontent.fsgn5-5.fna.fbcdn.net/v/t39.30808-1/449714722_122154711086155664_1640065658851446465_n.jpg?stp=c0.256.1536.1536a_cp0_dst-jpg_s50x50&_nc_cat=100&ccb=1-7&_nc_sid=0ecb9b&_nc_ohc=1JwPPJOeV84Q7kNvgEZGrcb&_nc_ht=scontent.fsgn5-5.fna&oh=00_AYA8lpkfy1HT6w_b2Or3Fmbhb1aNezcGaqblX2SKnMhm0A&oe=66DDB93D",
        "body": {
            "text": "hay",
            "ranges": [],
            "delight_ranges": [],
            "color_ranges": []
          }
        }
     * 
     */
    console.log('callbackComment', data);
    if (data && Array.isArray(data)) {
      data?.forEach((item) => {
        updateStatForFriend('comment', item?.id);
      });
    }
  };

  const getPostRemain = () => {
    try {
      const postIds = [...postsRef?.current] || [];
      setPosts([...postIds?.slice(1)]);
      return postIds[0];
    } catch (error) {
      console.log(error);
      return null;
    }
  };

  const getDataTask = async () => {
    const postId = getPostRemain();
    if (!postId) {
      // sort data
      emitterManageUI.send(SORT);
      onCancelPost();
      return;
    }

    const isPage = FBUserRef?.current?.isPageProfile || false;

    if (isPage) {
      // Get reactions
      const res = await getReactionsFromFeedback(postId, null, callbackReaction);
      if (res?.isOperationCanceled) {
        onCancelPost();
        return;
      }
      // Get comments
      const res2 = await getCommentInfoInPost(postId, '', callbackComment, true);
      if (res2?.isOperationCanceled) {
        onCancelPost();
        return;
      }
    } else {
      // Get reactions
      const res = await getReactionsInPost(postId, '', callbackReaction);
      if (res?.isOperationCanceled) {
        onCancelPost();
        return;
      }
      // Get comments
      const res2 = await getCommentInfoInPost(postId, '', callbackComment);
      if (res2?.isOperationCanceled) {
        onCancelPost();
        return;
      }
    }

    console.log('Run next cycle!');
    if (taskRef?.current) {
      clearTimeout(taskRef?.current);
    }

    taskRef.current = setTimeout(() => {
      getDataTask();
    }, DELAY_GET_DATA);
  };

  const onGetReactions = () => {
    if (!checkCommonData()) {
      return;
    }
    isRunning.onTrue();
    console.log('get reactions:', filterDataRef?.current);
    fetchInteractionData();
  };

  const getPostsOfPage = async (from, to) => {
    try {
      const { posts } = await getTimelinePostsOfPage(from, to, POSTS_OF_PAGE);
      return posts?.map((item) => item?.id);
    } catch (error) {
      console.log('getPostsTimeLineOfPage', error);
      return [];
    }
  };

  const fetchInteractionData = async () => {
    try {
      resetStats();
      restartSignal();
      // Get access token
      const accessToken = await onLoadAccessToken();
      // console.log('accessToken: ', accessToken);
      const {
        date: { from, to },
      } = filterDataRef?.current || {};

      // Get posts
      const isPage = FBUserRef?.current?.isPageProfile || false;
      const posts = isPage ? await getPostsOfPage(from, to) : await getPostsInProfile(from, to);
      console.log(posts);
      setPosts(posts);
      getDataTask();
    } catch (error) {
      console.log(error);
    }
  };

  // Add friends =====================================================
  const addListFriendsCallback = (result) => {
    const { profile, success } = result;
    if (profile) {
      const currentQueue = postQueuesRef?.current || [];
      currentQueue.push({ profile, success, type: 'add_friend' });
    }
  };

  const onAddListFriends = (data) => {
    if (!checkCommonData()) {
      return;
    }
    isRunning.onTrue();
    console.log('onAddListFriends:', data);
    addListFriends(data, addListFriendsCallback, isRunning.onFalse);
  };

  // ==================================================================

  // CONTROL SUBMIT BUTTON
  const onCancelPost = () => {
    stopQueue(false, 'manual');
    isRunning.onFalse();
    onReset();
    const temp = [...postQueues]?.filter((item) => ACTIONS_RESULT?.some((key) => item[key]));
    setPostQueues([...temp]);
  };

  const updateFilterSetting = (newValue) => {
    if (newValue?.filter) {
      emitterManageUI.send(DISABLE_ACTIONS);
    }
    setFilterData(newValue);
  };

  const getFilterData = () => {
    return filterDataRef?.current || {};
  };

  const updateActionsSetting = (newValue) => {
    const haveAnyOn = Object.keys(newValue)?.some((key) => newValue[key]);
    if (haveAnyOn) {
      emitterManageUI.send(DISABLE_FILTER);
    }
    setActionsSetting(newValue);
  };

  const getActionsSetting = () => {
    return actionsSettingRef?.current || {};
  };

  useEffect(() => {
    updateTaskRunningStatus(isRunning?.value);
  }, [isRunning?.value]);

  return (
    <InteractiveContext.Provider
      value={useMemo(
        () => ({
          postQueues,
          friends,
          isRunning: isRunning.value,
          emitterManageUI,
          onCancelPost,
          onStartInteract,
          getFilterData,
          updateFilterSetting,
          onGetReactions,
          getActionsSetting,
          updateActionsSetting,
          onAddListFriends,
        }),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [isRunning?.value, postQueues, friends]
      )}
    >
      {children}
    </InteractiveContext.Provider>
  );
}

export { InteractiveProvider, InteractiveContext };
